import { __extends } from "tslib";
import Floor from 'data/Floor';
var FloorSVG = /** @class */ (function (_super) {
    __extends(FloorSVG, _super);
    function FloorSVG(floorData) {
        var _this = _super.call(this, floorData) || this;
        _this.element = floorData.element;
        _this.pathGroup = floorData.paths;
        _this.paths = [];
        _this.poiGroup = floorData.pois;
        _this.rooms = new Map();
        return _this;
    }
    return FloorSVG;
}(Floor));
export default FloorSVG;
