/* eslint @typescript-eslint/no-use-before-define: 0, @typescript-eslint/no-magic-numbers: 0 */
/* !
* @svgdotjs/svg.panzoom.js - A plugin for svg.js that enables panzoom for viewport elements
* @version 2.0.3-mod
* https://github.com/svgdotjs/svg.panzoom.js#readme
*
* @copyright undefined
* @license MIT
*/
import { Svg, on, off, extend, Matrix, Box } from '@svgdotjs/svg.js';
var normalizeEvent = function (ev) { var _a; return _a = ev.touches, (_a !== null && _a !== void 0 ? _a : [{ clientX: ev.clientX, clientY: ev.clientY }]); };
extend(Svg, {
    panZoom: function (options) {
        var _this = this;
        var _a, _b, _c;
        this.off('.panZoom');
        this._panDisabled = false;
        // when called with false, disable panZoom
        if (options === false)
            return this;
        options = (options !== null && options !== void 0 ? options : {});
        var zoomFactor = (_a = options.zoomFactor, (_a !== null && _a !== void 0 ? _a : 0.03));
        var zoomMin = (_b = options.zoomMin, (_b !== null && _b !== void 0 ? _b : Number.MIN_VALUE));
        var zoomMax = (_c = options.zoomMax, (_c !== null && _c !== void 0 ? _c : Number.MAX_VALUE));
        var lastP, lastTouches;
        var zoomInProgress = false;
        var wheelZoom = function (ev) {
            ev.preventDefault();
            // touchpads can give ev.deltaY == 0, which skrews the lvl calculation
            if (ev.deltaY === 0)
                return;
            var lvl = _this.zoom() - zoomFactor * ev.deltaY / Math.abs(ev.deltaY);
            var p = _this.point(ev.clientX, ev.clientY);
            if (lvl > zoomMax) {
                lvl = zoomMax;
            }
            if (lvl < zoomMin) {
                lvl = zoomMin;
            }
            _this.zoom(lvl, p);
            _this.dispatch('zoom', { focus: p, level: lvl });
        };
        var pinchZoomStart = function (ev) {
            lastTouches = normalizeEvent(ev);
            if (lastTouches.length < 2)
                return;
            ev.preventDefault();
            if (_this.dispatch('pinchZoomStart', { event: ev }).defaultPrevented) {
                return;
            }
            _this.off('touchstart.panZoom', pinchZoomStart);
            zoomInProgress = true;
            on(document, 'touchmove.panZoom', pinchZoom, _this, { passive: false });
            on(document, 'touchend.panZoom', pinchZoomStop, _this, { passive: false });
        };
        var pinchZoomStop = function (ev) {
            ev.preventDefault();
            zoomInProgress = false;
            _this.dispatch('pinchZoomEnd', { event: ev });
            off(document, 'touchmove.panZoom', pinchZoom);
            off(document, 'touchend.panZoom', pinchZoomStop);
            _this.on('touchstart.panZoom', pinchZoomStart);
        };
        var pinchZoom = function (ev) {
            ev.preventDefault();
            var currentTouches = normalizeEvent(ev);
            var zoom = _this.zoom();
            // Distance Formula
            var lastDelta = Math.sqrt(Math.pow(lastTouches[0].clientX - lastTouches[1].clientX, 2)
                + Math.pow(lastTouches[0].clientY - lastTouches[1].clientY, 2));
            var currentDelta = Math.sqrt(Math.pow(currentTouches[0].clientX - currentTouches[1].clientX, 2)
                + Math.pow(currentTouches[0].clientY - currentTouches[1].clientY, 2));
            var zoomAmount = lastDelta / currentDelta;
            if ((zoom < zoomMin && zoomAmount > 1) || (zoom > zoomMax && zoomAmount < 1)) {
                zoomAmount = 1;
            }
            var currentFocus = {
                x: currentTouches[0].clientX + 0.5 * (currentTouches[1].clientX - currentTouches[0].clientX),
                y: currentTouches[0].clientY + 0.5 * (currentTouches[1].clientY - currentTouches[0].clientY),
            };
            var p = _this.point(currentFocus.x, currentFocus.y);
            var box = new Box(_this.viewbox()).transform(new Matrix()
                .translate(-p.x, -p.y)
                .scale(zoomAmount, 0, 0)
                .translate(p.x, p.y));
            _this.viewbox(box);
            lastTouches = currentTouches;
            _this.dispatch('zoom', { box: box, focus: p });
        };
        var panStart = function (ev) {
            // ev.preventDefault(); // Don't prevent - can't tap to click
            lastTouches = normalizeEvent(ev);
            if (lastTouches.length !== 1) {
                return;
            }
            _this.off('mousedown.panZoom', panStart);
            _this.off('touchstart.panZoom', panStart);
            if (zoomInProgress)
                return;
            _this.dispatch('panStart', { event: ev });
            lastP = { x: lastTouches[0].clientX, y: lastTouches[0].clientY };
            on(document, 'mousemove.panZoom', panning, _this);
            on(document, 'mouseup.panZoom', panStop, _this);
            on(document, 'touchmove.panZoom', panning, _this, { passive: false });
            on(document, 'touchend.panZoom', panStop, _this);
        };
        var panStop = function (ev) {
            // ev.preventDefault(); // Don't prevent - can't tap to click
            off(document, 'mousemove.panZoom', panning);
            off(document, 'mouseup.panZoom', panStop);
            off(document, 'touchmove.panZoom', panning);
            off(document, 'touchend.panZoom', panStop);
            _this.on('mousedown.panZoom', panStart);
            _this.on('touchstart.panZoom', panStart);
            _this.dispatch('panEnd', { event: ev });
        };
        var panning = function (ev) {
            if (_this._panDisabled) {
                return;
            }
            ev.preventDefault();
            var currentTouches = normalizeEvent(ev);
            var currentP = { x: currentTouches[0].clientX, y: currentTouches[0].clientY };
            var p1 = _this.point(currentP.x, currentP.y);
            var p2 = _this.point(lastP.x, lastP.y);
            var deltaP = [p2.x - p1.x, p2.y - p1.y];
            var box = new Box(_this.viewbox()).transform(new Matrix().translate(deltaP[0], deltaP[1]));
            _this.viewbox(box);
            _this.dispatch('panning', { box: box, event: ev });
            lastP = currentP;
        };
        this.on('touchstart.panZoom', pinchZoomStart);
        this.on('wheel.panZoom', wheelZoom);
        this.on('mousedown.panZoom', panStart, this);
        this.on('touchstart.panZoom', panStart, this);
        return this;
    },
});
