import { __read, __values } from "tslib";
import { Box, Matrix } from '@svgdotjs/svg.js';
import CeilingController from './CeilingController';
import Input from './Input';
import PathController from './PathController';
var S_TO_MS = 1000;
var WayfinderLogicSVG = /** @class */ (function () {
    function WayfinderLogicSVG(wayfinder, minimal) {
        var _this = this;
        if (minimal === void 0) { minimal = false; }
        this.wayfinder = wayfinder;
        this.minimal = minimal;
        wayfinder.events.listen('svg-setup', function (svg) { return _this.setup(svg); });
        this.pathController = new PathController(this);
    }
    WayfinderLogicSVG.prototype.closestFloor = function (room) {
        var e_1, _a;
        var _this = this;
        var activeFloor = this.wayfinder.building.getActiveFloor();
        var floors = this.wayfinder.building.getSortedFloors().filter(function (floor) { return !!_this.wayfinder.building.getFloor(floor.id).rooms.get(room); });
        if (activeFloor && floors.length) {
            var closest = floors[0];
            var closestI = Infinity;
            try {
                for (var floors_1 = __values(floors), floors_1_1 = floors_1.next(); !floors_1_1.done; floors_1_1 = floors_1.next()) {
                    var floor = floors_1_1.value;
                    if (Math.abs(floor.index - activeFloor.index) < closestI) {
                        closest = floor;
                        closestI = Math.abs(floor.index - activeFloor.index);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (floors_1_1 && !floors_1_1.done && (_a = floors_1.return)) _a.call(floors_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return closest.id;
        }
    };
    WayfinderLogicSVG.prototype.hideFloors = function () {
        var e_2, _a;
        try {
            for (var _b = __values(this.wayfinder.building.getFloors()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), floor = _d[1];
                floor.element.hide();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    WayfinderLogicSVG.prototype.setup = function (svg) {
        var e_3, _a, e_4, _b;
        var _this = this;
        var events = this.wayfinder.events;
        if (!this.minimal) {
            var rooms = svg.find('[wf\\:room]');
            try {
                for (var rooms_1 = __values(rooms), rooms_1_1 = rooms_1.next(); !rooms_1_1.done; rooms_1_1 = rooms_1.next()) {
                    var room = rooms_1_1.value;
                    var _loop_1 = function (ceiling) {
                        Input.createClickHandler(ceiling, function (_) {
                            events.trigger('ceiling-click', ceiling);
                        });
                    };
                    try {
                        for (var _c = (e_4 = void 0, __values(room.children())), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var ceiling = _d.value;
                            _loop_1(ceiling);
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (rooms_1_1 && !rooms_1_1.done && (_a = rooms_1.return)) _a.call(rooms_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        this.ceilingController = new CeilingController({
            highlightColors: [
                this.wayfinder.settings.get('poi.highlight.color1'),
                this.wayfinder.settings.get('poi.highlight.color2'),
            ],
            highlightDuration: this.wayfinder.settings.get('poi.highlight.duration') * S_TO_MS,
            highlightSpeed: this.wayfinder.settings.get('poi.highlight.speed') * S_TO_MS,
        });
        events.listen('floor-change-trigger', function (floor) { return _this.showFloor(floor); });
        events.listen('kiosk-show', function () { return _this.showKiosk(svg); });
        events.listen('ceiling-click', function (ceiling) { return _this.ceilingClicked(ceiling); });
        events.listen('path-show', function (room) { return _this.showPath(room); });
        events.listen('room-show', function (room) { return _this.showRoom(room); });
        events.listen('stop-show', function () { return _this.stopShow(); });
    };
    WayfinderLogicSVG.prototype.ceilingClicked = function (ceiling) {
        var _a;
        var room = parseInt((_a = ceiling.parent('[wf\\:room]')) === null || _a === void 0 ? void 0 : _a.attr('wf:room'));
        if (!isNaN(room)) {
            var poi = this.wayfinder.roomToPoi.get(room);
            if (poi) {
                this.wayfinder.events.trigger('poi-select', poi);
            }
        }
    };
    WayfinderLogicSVG.prototype.showFloor = function (floor) {
        var _a;
        this.wayfinder.events.trigger('floor-change-start');
        var building = this.wayfinder.building;
        var currentFloor = building.getActiveFloor();
        if (building.setActiveFloor(floor)) {
            (_a = currentFloor) === null || _a === void 0 ? void 0 : _a.element.hide();
            building.getActiveFloor().element.show();
        }
        this.wayfinder.events.trigger('floor-change-done', floor);
    };
    WayfinderLogicSVG.prototype.showKiosk = function (svg) {
        var _a, _b, _c, _d, _e;
        var kiosk = this.wayfinder.pois.get(parseInt(this.wayfinder.settings.get('kiosk.default')));
        var kioskFloor = (_c = (_b = (_a = kiosk) === null || _a === void 0 ? void 0 : _a.getFloor()) === null || _b === void 0 ? void 0 : _b.id, (_c !== null && _c !== void 0 ? _c : this.wayfinder.building.getSortedFloors()[0].id));
        this.showFloor(kioskFloor);
        if (kiosk) {
            var b = new Box(svg.viewbox());
            var kioskEl = (_d = this.wayfinder.building.getFloor(kioskFloor)) === null || _d === void 0 ? void 0 : _d.rooms.get(kiosk.getRoomId());
            var kioskBox = (_e = kioskEl) === null || _e === void 0 ? void 0 : _e.bbox();
            if (kioskBox) {
                var cx = kioskBox.cx, cy = kioskBox.cy;
                var dx = cx - b.cx;
                var dy = cy - b.cy;
                svg.viewbox(b.transform(new Matrix().translate(dx, dy)));
            }
        }
        this.wayfinder.events.trigger('kiosk-show-done');
    };
    WayfinderLogicSVG.prototype.showPath = function (room) {
        var _a;
        this.stopShow();
        try {
            var kiosk = (_a = this.wayfinder.pois.get(parseInt(this.wayfinder.settings.get('kiosk.default')))) === null || _a === void 0 ? void 0 : _a.getRoomId();
            if (kiosk) {
                var closestFloor = this.closestFloor(room);
                var kioskFloor = this.closestFloor(kiosk);
                if (kioskFloor && closestFloor) {
                    var path = this.wayfinder.pathGraph.findPath(kioskFloor, kiosk, closestFloor, room);
                    this.showRoom(room, false);
                    this.pathController.showPath(path);
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    WayfinderLogicSVG.prototype.showRoom = function (room, showFloor) {
        if (showFloor === void 0) { showFloor = true; }
        this.stopShow();
        var closestFloor = this.closestFloor(room);
        if (closestFloor) {
            if (showFloor) {
                this.showFloor(closestFloor);
            }
            var element = this.wayfinder.building.getFloor(closestFloor).rooms.get(room);
            if (element) {
                this.ceilingController.highlightRoom(element);
            }
        }
    };
    WayfinderLogicSVG.prototype.stopShow = function () {
        this.pathController.hidePath();
        this.ceilingController.stopHighlight();
    };
    return WayfinderLogicSVG;
}());
export default WayfinderLogicSVG;
