import { __read, __values } from "tslib";
/**
 * WayfinderOptions base class.
 * @class
 */
var WayfinderOptions = /** @class */ (function () {
    function WayfinderOptions() {
        this.application = 'wayfinder';
        this.drawKioskIcon = true;
        this.language = 'en';
        this.map = 'map';
        this.project = 'demo';
        // this.disablePathDrawing = false;		// Disables path drawing
    }
    /**
        @example http://app.3dwayfinder.com/projects/demo/#{"disableModelLoading": true}
    */
    WayfinderOptions.prototype.loadFromURL = function () {
        var e_1, _a, e_2, _b;
        if (location.hash.length >= 2 && location.hash.indexOf('{') > -1) {
            var args = unescape(location.hash.substring(1)).split('#');
            if (args.length >= 1 && args[0].indexOf('{') > -1 && args[0].indexOf('}') > -1) {
                var options = JSON.parse(args[0]);
                try {
                    for (var _c = __values(Object.entries(options)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var _e = __read(_d.value, 2), i = _e[0], option = _e[1];
                        this[i] = option;
                        if (i === 'kiosk') {
                            this['kiosk.default'] = option;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        else if (location.search.length >= 2) {
            var options = unescape(location.search.substring(1)).split('&');
            if (options.length >= 1) {
                try {
                    for (var options_1 = __values(options), options_1_1 = options_1.next(); !options_1_1.done; options_1_1 = options_1.next()) {
                        var keyOption = options_1_1.value;
                        var option = keyOption.split('=');
                        if (option.length > 1) {
                            this[option[0]] = option[1];
                            if (option[0] === 'kiosk') {
                                this['kiosk.default'] = option[1];
                            }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (options_1_1 && !options_1_1.done && (_b = options_1.return)) _b.call(options_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    };
    return WayfinderOptions;
}());
export default WayfinderOptions;
