import { __extends, __read, __values } from "tslib";
import POI from 'data/POI';
var FONT_SIZE = 20;
var PoiSVG = /** @class */ (function (_super) {
    __extends(PoiSVG, _super);
    function PoiSVG(wayfinder, data) {
        var _this = _super.call(this, wayfinder, data) || this;
        _this.wayfinder = wayfinder;
        _this.geometries = new Map();
        _this.elements = new Map();
        return _this;
    }
    PoiSVG.prototype.createIconGeometry = function (floor, svg) {
        var icon = svg.image(this.wayfinder.api.images.get.url(this.imageId));
        var width = parseInt(this.wayfinder.settings.get('poi.width'));
        var height = parseInt(this.wayfinder.settings.get('poi.height'));
        icon.size(width, height);
        this.setNode(floor, icon);
    };
    PoiSVG.prototype.createTextGeometry = function (floor, svg) {
        var createTextGeometry = svg.text(this.getName(this.wayfinder.getLanguage()));
        createTextGeometry.font({
            family: 'Tahoma, Geneva, sans-serif',
            fill: '#ffffff',
            size: FONT_SIZE,
            stroke: '#000000',
        });
        this.setNode(floor, createTextGeometry);
    };
    PoiSVG.prototype.setNode = function (floor, node) {
        // TODO: decorate, offset
        node.attr('style', 'pointer-events: none');
        node.attr('wf:internal', true);
        this.wayfinder.building.getFloor(floor).poiGroup.add(node);
        this.geometries.set(floor, node);
        this.syncGeometry();
    };
    PoiSVG.prototype.checkGeometry = function () {
        var e_1, _a;
        var _b;
        try {
            for (var _c = __values(this.elements), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), floor = _e[0], element = _e[1];
                if (!this.wayfinder.building.getFloor(floor) ||
                    element.children().filter(function (el) { return !el.attr('wf:internal'); }).length === 0) {
                    (_b = this.geometries.get(floor)) === null || _b === void 0 ? void 0 : _b.remove();
                    this.geometries.delete(floor);
                    element.remove();
                    this.elements.delete(floor);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    PoiSVG.prototype.createGeometry = function (svg) {
        var e_2, _a, e_3, _b;
        this.checkGeometry();
        try {
            for (var _c = __values(this.geometries), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), floor = _e[0], geometry = _e[1];
                geometry.remove();
                this.geometries.delete(floor);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            for (var _f = __values(this.elements), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), floor = _h[0], element = _h[1];
                if (element.children().filter(function (el) { return !el.attr('wf:internal'); }).length > 0) {
                    if (this.imageId > 0 && !this.wayfinder.settings.get('poi.map.only-text')) {
                        this.createIconGeometry(floor, svg);
                    }
                    else if (this.wayfinder.settings.get('poi.2d.display-names-always') || this.alwaysVisible) {
                        this.createTextGeometry(floor, svg);
                    }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    PoiSVG.prototype.getFloor = function () {
        if (this.elements.size > 0) {
            return this.wayfinder.building.getFloor(this.elements.keys().next().value);
        }
    };
    PoiSVG.prototype.setElement = function (floor, element) {
        this.elements.set(floor, element);
    };
    PoiSVG.prototype.syncGeometry = function () {
        var e_4, _a;
        try {
            for (var _b = __values(this.geometries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), floor = _d[0], geometry = _d[1];
                var element = this.elements.get(floor);
                if (geometry.type === 'text') {
                    geometry.font('size', FONT_SIZE);
                    var _e = element.bbox(), elW = _e.w, elH = _e.h;
                    var _f = geometry.bbox(), w = _f.w, h = _f.h;
                    var d = Math.max(w / elW, h / elH);
                    if (d > 1) {
                        geometry.font('size', FONT_SIZE / d);
                    }
                }
                geometry.center(element.cx(), element.cy());
                geometry.front();
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    return PoiSVG;
}(POI));
export default PoiSVG;
