import { __awaiter, __generator } from "tslib";
import { OK, MULTIPLE_CHOICES } from 'ext/statuses';
var XHRState;
(function (XHRState) {
    XHRState[XHRState["Unsent"] = 0] = "Unsent";
    XHRState[XHRState["Opened"] = 1] = "Opened";
    XHRState[XHRState["HeadersReceived"] = 2] = "HeadersReceived";
    XHRState[XHRState["Loading"] = 3] = "Loading";
    XHRState[XHRState["Done"] = 4] = "Done";
})(XHRState || (XHRState = {}));
function makeHTTPRequest(method, url, parse, data, type) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var xhr = new XMLHttpRequest();
                    var params;
                    xhr.open(method, url, true);
                    if (type) {
                        xhr.overrideMimeType(type);
                    }
                    if (data) {
                        xhr.setRequestHeader('Content-Type', 'x-www-form-urlencoded');
                        params = "parameters=" + encodeURIComponent(JSON.stringify(data));
                    }
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === XHRState.Done) {
                            if (xhr.status >= OK && xhr.status <= MULTIPLE_CHOICES) {
                                resolve(parse(xhr.responseText));
                            }
                            else {
                                reject({
                                    status: xhr.status,
                                    statusText: xhr.statusText,
                                });
                            }
                        }
                    };
                    xhr.onerror = function () {
                        reject({
                            status: xhr.status,
                            statusText: xhr.statusText,
                        });
                    };
                    xhr.send(params);
                })];
        });
    });
}
export function get(url, parse, type) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, makeHTTPRequest('GET', url, parse, undefined, type)];
        });
    });
}
export function getImage(url) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var image = new Image();
                    image.addEventListener('load', function () {
                        resolve(image);
                    });
                    image.addEventListener('error', reject);
                    image.src = url;
                })];
        });
    });
}
export function getJSON(url) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, get(url, function (r) { return JSON.parse(r); }, 'text/json')];
        });
    });
}
export function getText(url) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, get(url, function (r) { return r; }, 'text/plain')];
        });
    });
}
export function post(url, parse, data, type) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, makeHTTPRequest('POST', url, parse, data, type)];
        });
    });
}
export function postJSON(url, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, post(url, function (r) { return JSON.parse(r); }, data, 'text/json')];
        });
    });
}
