import { __read, __values } from "tslib";
// Pollute polyfills
import 'core-js/es/map';
import 'core-js/es/promise';
import 'core-js/es/reflect';
import 'core-js/es/array/entries';
import 'core-js/es/array/includes';
import 'core-js/es/object/entries';
import 'core-js/es/string/starts-with';
import 'core-js/es/string/ends-with';
import 'core-js/es/string/trim';
import 'proxy-polyfill';
import WayfinderAPI from 'WayfinderAPI';
import WayfinderEvents from 'WayfinderEvents';
import WayfinderFactory from 'WayfinderFactory';
import WayfinderOptions from 'WayfinderOptions';
import Translator from 'Translator';
export var LoadingState;
(function (LoadingState) {
    LoadingState[LoadingState["Loading"] = 0] = "Loading";
    LoadingState[LoadingState["Done"] = 1] = "Done";
})(LoadingState || (LoadingState = {}));
var Wayfinder = /** @class */ (function () {
    function Wayfinder(options, factory) {
        if (options === void 0) { options = new WayfinderOptions(); }
        if (factory === void 0) { factory = new WayfinderFactory(); }
        this.options = options;
        this.factory = factory;
        this.firstFinishedLoading = false;
        this.factory.wayfinder = this;
        this.setOptions();
        this.api = new WayfinderAPI();
        this.events = new WayfinderEvents();
        this.pois = new Map();
        this.roomToPoi = new Map();
        this.settings = new Map();
        this.translator = new Translator(this.options.language, {});
    }
    Wayfinder.prototype.setOptions = function () {
        this.options.project = this.readProjectName();
        this.options.loadFromURL();
    };
    Wayfinder.prototype.finishedLoading = function () {
        if (!this.firstFinishedLoading) {
            this.firstFinishedLoading = true;
            this.onDataLoaded();
        }
    };
    Wayfinder.prototype.onDataLoaded = function () {
        this.events.trigger('loading', LoadingState.Done);
    };
    Wayfinder.prototype.readProjectName = function () {
        var path = document.location.pathname;
        var folders = path.split('/');
        for (var i = 0; i < folders.length; i++) {
            // Found a folder called 'projects', the project name must be the next one
            if (folders[i] === 'projects') {
                if (folders.length > (i + 1)) {
                    return folders[i + 1];
                }
            }
        }
        return this.options.project;
    };
    Wayfinder.prototype.getLanguage = function () {
        return this.translator.getLanguage();
    };
    Wayfinder.prototype.onBundleData = function (response) {
        var e_1, _a;
        var settings = response.settings;
        try {
            for (var _b = __values(Object.entries(settings)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                this.settings.set(key, value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (typeof response.guitranslations !== 'undefined') {
            this.translator.setTranslations(response.guitranslations);
        }
    };
    Wayfinder.prototype.open = function (project) {
        if (project) {
            this.options.project = project;
        }
        this.api.open(this.options.project);
        this.startLoading();
    };
    Wayfinder.prototype.resize = function () {
        var map = document.getElementById(this.options.map);
        if (map && map.parentElement) {
            var style = window.getComputedStyle(map.parentElement);
            if (style.width !== null && style.height !== null) {
                map.setAttribute('width', style.width);
                map.setAttribute('height', style.height);
            }
        }
    };
    Wayfinder.prototype.setLanguage = function (language) {
        this.translator.translate(language);
        this.events.trigger('language-change', language);
    };
    return Wayfinder;
}());
export default Wayfinder;
