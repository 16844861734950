import { __read, __spread, __values } from "tslib";
var CeilingController = /** @class */ (function () {
    function CeilingController(settings) {
        this.settings = settings;
        this.activeCeilings = [];
    }
    CeilingController.prototype.animate = function (ceiling) {
        var e_1, _a;
        var _this = this;
        var speed = this.settings.highlightSpeed;
        var originalColor = ceiling.node.style.fill;
        var runner = ceiling.animate();
        var timeline = runner.timeline();
        if (this.settings.highlightColors.length > 0) {
            timeline.persist(true);
            var colorLen = '#xxxxxx'.length;
            var firstColor = this.settings.highlightColors[0].slice(0, colorLen);
            ceiling.css('fill', firstColor);
            try {
                for (var _b = __values(__spread(this.settings.highlightColors.slice(1), [firstColor])), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var color = _c.value;
                    runner.animate(speed).css('fill', color.slice(0, colorLen));
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var endTime_1 = timeline.getEndTime();
            var runTime_1 = 0;
            var lastTime_1 = 0;
            timeline.on('time', function (t) {
                var time = t.detail;
                var dt = time - lastTime_1;
                lastTime_1 = time;
                runTime_1 += dt;
                if (runTime_1 > _this.settings.highlightDuration) {
                    timeline.off('time'); // Before next line - otherwise we get recursion
                    timeline.stop();
                }
                if (time > endTime_1) {
                    lastTime_1 = 0; // Before next line - otherwise we get recursion
                    timeline.time(0);
                }
            });
            this.activeCeilings.push({
                ceiling: ceiling,
                originalColor: originalColor,
                timeline: timeline,
            });
        }
    };
    CeilingController.prototype.highlightRoom = function (room) {
        var e_2, _a;
        try {
            for (var _b = __values(room.children().filter(function (el) { return !el.attr('wf:internal'); })), _c = _b.next(); !_c.done; _c = _b.next()) {
                var ceiling = _c.value;
                this.animate(ceiling);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    CeilingController.prototype.stopHighlight = function () {
        var c;
        while (c = this.activeCeilings.pop()) {
            c.timeline.stop();
            c.ceiling.css('fill', c.originalColor);
        }
    };
    return CeilingController;
}());
export default CeilingController;
