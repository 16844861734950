import { __assign, __awaiter, __extends, __generator, __read, __spread } from "tslib";
import { namespaces, SVG } from '@svgdotjs/svg.js';
import './ext/panzoom';
import { getText, getJSON } from 'Logistics';
import Wayfinder from 'Wayfinder';
import WayfinderOptions from '../WayfinderOptions';
import WayfinderFactorySVG from './WayfinderFactorySVG';
import WayfinderLogicSVG from './WayfinderLogicSVG';
import Input from './Input';
var WayfinderSVG = /** @class */ (function (_super) {
    __extends(WayfinderSVG, _super);
    function WayfinderSVG(minimal, options) {
        if (minimal === void 0) { minimal = false; }
        if (options === void 0) { options = new WayfinderOptions(); }
        var _this = _super.call(this, options, new WayfinderFactorySVG()) || this;
        _this.logic = new WayfinderLogicSVG(_this, minimal);
        _this.poiIdToRoom = new Map();
        _this.options.map = 'map';
        return _this;
    }
    WayfinderSVG.prototype.setupSVG = function (data) {
        var map = SVG("#" + this.options.map);
        map.svg(data);
        try {
            this.svg = map.first();
            // https://github.com/svgdotjs/svg.js/issues/709 - Which isn't fixed despite being closed...
            this.svg.node.removeAttribute('xmlns:svgjs');
            this.svg.node.setAttribute('xmlns:svgjs', namespaces.svgjs);
            // Our own namespace
            this.svg.node.removeAttribute('xmlns:wf');
            this.svg.node.setAttribute('xmlns:wf', 'http://3dwayfinder.com');
            this.input = new Input(this.svg);
            window.onresize = this.resize.bind(this);
        }
        catch (e) {
            console.error('Error setting up SVG');
        }
    };
    WayfinderSVG.prototype.resize = function () {
        var w = this.svg.parent().node.offsetWidth;
        var h = this.svg.parent().node.offsetHeight;
        var _a = this.svg.viewbox(), x = _a.x, y = _a.y, width = _a.width;
        var zoom = width / this.svg.width();
        this.svg.size(w, h);
        this.svg.viewbox(x, y, w * zoom, h * zoom);
    };
    WayfinderSVG.prototype.startLoading = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, svg, bundle, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all([
                                getText('./test.svg'),
                                getJSON(this.api.svg.bundle.url() + "?t=" + Date.now())
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), svg = _a[0], bundle = _a[1];
                        console.log.apply(console, __spread(['bundle'], bundle.data));
                        this.onBundleData(__assign(__assign({}, bundle.data), { svg: svg }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: types
    WayfinderSVG.prototype.onBundleData = function (data) {
        _super.prototype.onBundleData.call(this, data);
        var svg = data.svg;
        this.setupSVG(svg);
        this.locations = data.locations;
        this.factory.setup(this.svg, this.locations);
        this.svg.panZoom({ zoomFactor: 0.1 });
        this.events.trigger('svg-setup', this.svg);
        this.finishedLoading();
        this.resize();
        // After loaded actions
        this.logic.hideFloors();
        this.events.trigger('kiosk-show');
    };
    return WayfinderSVG;
}(Wayfinder));
export default WayfinderSVG;
