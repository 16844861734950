import { on, off } from '@svgdotjs/svg.js';
var MAX_CLICK_DELTA = 10;
var Input = /** @class */ (function () {
    function Input(svg) {
        this.svg = svg;
    }
    Input.createClickHandler = function (element, handler) {
        var startDrag = [0, 0];
        element.on('mousedown.wf touchstart.wf', function (ev) {
            startDrag = [ev.screenX, ev.screenY];
        });
        element.on('click.wf', function (ev) {
            var dx = ev.screenX - startDrag[0];
            var dy = ev.screenY - startDrag[1];
            if ((dx * dx + dy * dy) > MAX_CLICK_DELTA) {
                return;
            }
            handler(ev);
        });
    };
    Input.prototype.getXY = function (ev) {
        var pX, pY;
        if (ev instanceof MouseEvent) {
            pX = ev.pageX;
            pY = ev.pageY;
        }
        else {
            if (ev.changedTouches.length > 0) {
                pX = ev.changedTouches[0].pageX;
                pY = ev.changedTouches[0].pageY;
            }
            else {
                return;
            }
        }
        var _a = this.svg.point(pX, pY), x = _a.x, y = _a.y;
        return { x: x, y: y };
    };
    /**
     * @param {AbsoluteHandler} downCB - If this return true, the handlers aren't bound
     */
    Input.prototype.createAbsoluteDragHandler = function (element, downCB, moveCB, upCB) {
        var _this = this;
        var down = function (ev) {
            ev.preventDefault();
            var start = _this.getXY(ev);
            if (!start) {
                return;
            }
            _this.svg._panDisabled = true;
            if (!downCB(start)) {
                var move_1 = function (ev) {
                    var xy = _this.getXY(ev);
                    if (!xy) {
                        return;
                    }
                    moveCB(xy);
                };
                var up_1 = function (ev) {
                    var xy = _this.getXY(ev);
                    if (!xy) {
                        xy = start;
                    }
                    _this.svg._panDisabled = false;
                    upCB(xy);
                    off(document, 'mousemove.wf touchmove.wf', move_1);
                    off(document, 'mouseup.wf touchend.wf', up_1);
                };
                on(document, 'mouseup.wf touchend.wf', up_1);
                on(document, 'mousemove.wf touchmove.wf', move_1);
            }
        };
        element.on('mousedown.wf touchstart.wf', down);
        return function () {
            element.off('mousedown.wf touchstart.wf', down);
        };
    };
    Input.prototype.createClickHandler = function (handler) {
        var _this = this;
        var startDrag = [0, 0];
        var start = function (ev) {
            startDrag = [ev.screenX, ev.screenY];
        };
        this.svg.on('mousedown.wf touchstart.wf', start);
        var click = function (ev) {
            var dx = ev.screenX - startDrag[0];
            var dy = ev.screenY - startDrag[1];
            if ((dx * dx + dy * dy) > MAX_CLICK_DELTA) {
                return;
            }
            var elements = Input.elementsFromPoint(ev.clientX, ev.clientY);
            handler(elements);
        };
        this.svg.on('click.wf', click);
        return function () {
            _this.svg.off('mousedown.wf touchstart.wf', start);
            _this.svg.off('click.wf', click);
        };
    };
    Input.prototype.createDragHandler = function (element, downCB, moveCB, upCB) {
        var last;
        return this.createAbsoluteDragHandler(element, function (ev) {
            last = ev;
            downCB();
        }, function (ev) {
            moveCB({ dx: ev.x - last.x, dy: ev.y - last.y });
            last = ev;
        }, function (_) {
            upCB();
        });
    };
    var _a;
    Input.elementsFromPoint = (_a = document.elementsFromPoint.bind(document), (_a !== null && _a !== void 0 ? _a : function (x, y) {
        var parents = [];
        var parent;
        do {
            var element = document.elementFromPoint(x, y);
            if (element && parent !== element) {
                parent = element;
                parents.push(parent);
                parent.style.pointerEvents = 'none';
            }
            else {
                parent = false;
            }
        } while (parent);
        parents.forEach(function (parent) {
            parent.style.pointerEvents = 'all';
        });
        return parents;
    }));
    return Input;
}());
export default Input;
