var Floor = /** @class */ (function () {
    function Floor(floorData) {
        var _this = this;
        this.active = false;
        this.id = floorData.id;
        // this.name_id=parseInt(floorData.name_id, 10);
        // this.model_id=parseInt(floorData.model_id, 10);
        this.index = parseInt(floorData.index);
        // this.y=parseFloat(floorData.y, 10);
        // this.lightmap_id=parseInt(floorData.lightmap_id, 10);
        this.showInMenu = floorData.showInMenu;
        // this.names = new Translations();
        this.names = {
            get: function () { return "" + _this.id; },
        };
        // for (var language in languages) {
        // 	this.names.set(language, floorData[language]);
        // }
        // this.pois = [];
        // this.nodes = [];
    }
    Floor.prototype.getID = function () {
        return this.id;
    };
    Floor.prototype.getName = function () {
        return this.id;
    };
    Floor.prototype.getShowInMenu = function () {
        return this.showInMenu;
    };
    Floor.prototype.setActive = function (_active) {
        this.active = _active;
    };
    Floor.prototype.getActive = function () {
        return this.active;
    };
    return Floor;
}());
export default Floor;
