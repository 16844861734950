import { __awaiter, __generator } from "tslib";
import { getJSON } from './Logistics';
var WayfinderAPI = /** @class */ (function () {
    function WayfinderAPI(project, location) {
        this.project = project;
        this.location = location;
        if (!location) {
            this.location = '//api.3dwayfinder.com/';
        }
        // To avoid having to generate the API "object"
        return new Proxy(this, {
            get: function (target, className, receiver) {
                if (Reflect.has(target, className)) {
                    return Reflect.get(target, className, receiver);
                }
                else {
                    return new Proxy({}, {
                        get: function (_, method) {
                            var _this = this;
                            var fn = function () {
                                var args = [];
                                for (var _i = 0; _i < arguments.length; _i++) {
                                    args[_i] = arguments[_i];
                                }
                                return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    return [2 /*return*/, getJSON(target.getURL(String(className), String(method), args))];
                                }); });
                            };
                            fn.url = function () {
                                var args = [];
                                for (var _i = 0; _i < arguments.length; _i++) {
                                    args[_i] = arguments[_i];
                                }
                                return target.getURL(String(className), String(method), args);
                            };
                            return fn;
                        },
                    });
                }
            },
        });
    }
    WayfinderAPI.prototype.getURL = function (classname, method, args) {
        if (!this.project)
            throw 'No project opened! Call WayfinderAPI.open(<project name>);';
        args = args || [];
        return [this.location, 'public', this.project, classname, method].concat(args).join('/');
    };
    WayfinderAPI.prototype.open = function (project) {
        this.project = project;
    };
    return WayfinderAPI;
}());
export default WayfinderAPI;
