import { __read, __spread, __values } from "tslib";
var WayfinderEvents = /** @class */ (function () {
    function WayfinderEvents() {
        this.index = 0;
        this.events = new Map();
    }
    WayfinderEvents.prototype.listen = function (type, callback) {
        var eventCBs = this.events.get(type);
        if (!eventCBs) {
            eventCBs = new Map();
            this.events.set(type, eventCBs);
        }
        eventCBs.set(this.index, callback);
        var i = this.index;
        this.index++;
        return function () {
            eventCBs.delete(i);
        };
    };
    WayfinderEvents.prototype.trigger = function (type) {
        var e_1, _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var eventCBs = this.events.get(type);
        if (eventCBs) {
            try {
                for (var eventCBs_1 = __values(eventCBs), eventCBs_1_1 = eventCBs_1.next(); !eventCBs_1_1.done; eventCBs_1_1 = eventCBs_1.next()) {
                    var _b = __read(eventCBs_1_1.value, 2), cb = _b[1];
                    cb.apply(void 0, __spread(args));
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (eventCBs_1_1 && !eventCBs_1_1.done && (_a = eventCBs_1.return)) _a.call(eventCBs_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    return WayfinderEvents;
}());
export default WayfinderEvents;
