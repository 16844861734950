import { __extends, __read, __values } from "tslib";
import Building from 'data/Building';
var BuildingSVG = /** @class */ (function (_super) {
    __extends(BuildingSVG, _super);
    function BuildingSVG() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BuildingSVG.prototype.getActiveFloor = function () {
        return this.activeFloor;
    };
    BuildingSVG.prototype.getFloor = function (id) {
        return this.floors.get(id);
    };
    BuildingSVG.prototype.getFloors = function () {
        return this.floors;
    };
    BuildingSVG.prototype.getSortedFloors = function () {
        var e_1, _a;
        var a = [];
        try {
            for (var _b = __values(this.floors), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), floor = _d[1];
                a.push(floor);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return a.sort(function (a, b) { return a.index < b.index ? -1 : a.index === b.index ? 0 : 1; });
    };
    return BuildingSVG;
}(Building));
export default BuildingSVG;
