import { __read, __values } from "tslib";
var Building = /** @class */ (function () {
    function Building(_settings) {
        // this.name=settings["building.name"];
        // this.address=settings["building.address"];
        // this.link=new Translations(settings["building.link"]);
        // this.description=new Translations(settings["building.description"]);
        // this.logoID=settings["building.logo"];
        // this.backgroundID=settings["building.background"];
        this.floors = new Map();
    }
    Building.prototype.addFloor = function (floor) {
        this.floors.set(floor.id, floor);
    };
    Building.prototype.removeFloor = function (floor) {
        this.floors.delete(floor.id);
        if (floor === this.activeFloor) {
            this.activeFloor = undefined;
        }
    };
    Building.prototype.getActiveFloor = function () {
        return this.activeFloor;
    };
    Building.prototype.getFloor = function (id) {
        return this.floors.get(id);
    };
    Building.prototype.getFloors = function () {
        return this.floors;
    };
    Building.prototype.getSortedFloors = function () {
        var e_1, _a;
        var a = [];
        try {
            for (var _b = __values(this.floors), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), floor = _d[1];
                a.push(floor);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return a.sort(function (a, b) { return a.index < b.index ? -1 : a.index === b.index ? 0 : 1; });
    };
    Building.prototype.setActiveFloor = function (floor) {
        var _a;
        var newFloor = this.floors.get(floor);
        if (newFloor) {
            (_a = this.activeFloor) === null || _a === void 0 ? void 0 : _a.setActive(false);
            this.activeFloor = newFloor;
            this.activeFloor.setActive(true);
            return true;
        }
        return false;
    };
    return Building;
}());
export default Building;
