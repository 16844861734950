import { __values } from "tslib";
function _isJQueryObject(obj) {
    return (typeof jQuery !== 'undefined' && obj instanceof jQuery);
}
/**
    Automagically translates all marked HTML elements

    data-translation-attributes='title,value' - mark which attributes should be translated
    data-translation-attribute-title='key' - set translation map key for attribute title
    data-translation-element='key' - translates the element inner text from translationMap

    @param language Default language
    @param translationMap {object} {"key": {"en": "value"}}
 */
var Translator = /** @class */ (function () {
    function Translator(language, translations) {
        this.language = language;
        this.translations = translations;
    }
    /**
    * @param translationMap {object} {"key": {"en": "value"}}
    */
    Translator.prototype.setTranslations = function (translationMap) {
        this.translations = translationMap;
    };
    Translator.prototype.setLanguage = function (language) {
        this.language = language;
    };
    /** @return Current language of translator */
    Translator.prototype.getLanguage = function () {
        return this.language;
    };
    Translator.prototype.get = function (key, params) {
        if (this.translations && key && this.translations[key] && this.translations[key][this.language]) {
            var str = this.translations[key][this.language];
            if (params) {
                str = this.replaceValues(str, params);
            }
            return str;
        }
        // if the translation wasn't found return the key
        return key;
    };
    /** Translates all elements that have 'data-lang-<language>' attribute */
    Translator.prototype.translate = function (language) {
        var _this = this;
        if (language) {
            this.language = language;
        }
        // translate elements
        if (typeof document !== 'undefined' && document.querySelectorAll) {
            var elements = document.querySelectorAll('[data-translation-element]');
            elements.forEach(function (element) {
                return _this.translateElement(element, element.getAttribute('data-translation-element'));
            });
            // translate attributes
            elements = document.querySelectorAll('[data-translation-attributes]');
            elements.forEach(function (element) {
                var e_1, _a;
                var _b, _c, _d;
                var attributes = (_b = element.getAttribute('data-translation-attributes')) === null || _b === void 0 ? void 0 : _b.split(',');
                try {
                    for (var _e = __values((_d = (_c = attributes) === null || _c === void 0 ? void 0 : _c.filter(function (a) { return !!a; }), (_d !== null && _d !== void 0 ? _d : []))), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var attribute = _f.value;
                        var key = element.getAttribute("data-translation-attribute-" + attribute);
                        if (key) {
                            element.setAttribute(attribute, _this.get(key));
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            });
        }
    };
    /**
        Sets translation attributes for element translating
        @param element {jQuery object}
        @param key {string}
    */
    Translator.prototype.setElement = function (element, key) {
        if (element && key) {
            if (_isJQueryObject(element)) {
                element = element[0];
            }
            element.setAttribute('data-translation-element', key);
        }
    };
    Translator.prototype.setAttribute = function (element, attribute, key) {
        if (element && key && attribute) {
            if (_isJQueryObject(element)) {
                element = element[0];
            }
            var attr = [];
            if (element.getAttribute('data-translation-attributes')) {
                attr = element.getAttribute('data-translation-attributes').split(',');
            }
            attr.push(attribute);
            element.setAttribute('data-translation-attributes', attr.join(','));
            element.setAttribute("data-translation-attribute-" + attribute, key);
        }
    };
    /** Adds elements as translations
        @param parent Parent JQuery element
        @param key
    */
    Translator.prototype.translateElement = function (parent, key, params) {
        if (parent) {
            if (_isJQueryObject(parent)) {
                parent = parent[0];
            }
            if (key && this.exists(key)) {
                var value = this.get(key, params);
                this.setElement(parent, key);
                parent.innerHTML = value;
            }
            else {
                if (parent.classList) {
                    parent.classList.add('no-translation');
                }
            }
        }
    };
    /** Adds translations for single attribute */
    Translator.prototype.translateAttribute = function (parent, attribute, key, params) {
        if (parent && attribute && key) {
            if (_isJQueryObject(parent)) {
                parent = parent[0];
            }
            var value = this.get(key, params);
            this.setAttribute(parent, attribute, key);
            parent.setAttribute(attribute, value);
            // if no translation then add a class
            if (!this.exists(key) && parent.classList) {
                parent.classList.add('no-translation');
            }
        }
    };
    Translator.prototype.replaceValues = function (str, params) {
        var e_2, _a;
        if (str && params) {
            var count = 0;
            try {
                for (var params_1 = __values(params), params_1_1 = params_1.next(); !params_1_1.done; params_1_1 = params_1.next()) {
                    var param = params_1_1.value;
                    str = str.replace("%" + count++, param);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (params_1_1 && !params_1_1.done && (_a = params_1.return)) _a.call(params_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return str;
    };
    /**
    *	Check if translation exists in the table
    */
    Translator.prototype.exists = function (key) {
        if (this.translations && key && this.translations[key] && this.translations[key][this.language]) {
            return true;
        }
        else {
            return false;
        }
    };
    return Translator;
}());
export default Translator;
