import { __values } from "tslib";
var ANIMATION_SPEED = 50; // Units / sec
var S_TO_MS = 1000;
var PathController = /** @class */ (function () {
    function PathController(logic) {
        this.logic = logic;
        this.activePath = [];
    }
    PathController.prototype.hidePath = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.activePath), _c = _b.next(); !_c.done; _c = _b.next()) {
                var p = _c.value;
                p.timeline.finish();
                p.path.attr('stroke-dasharray', null);
                p.path.attr('stroke-dashoffset', null);
                p.path.hide();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.activePath = [];
    };
    PathController.prototype.showPath = function (path) {
        var e_2, _a;
        var _this = this;
        this.hidePath();
        if (path.length < 1) {
            return;
        }
        var previousFloor = path[0].floor;
        this.logic.showFloor(previousFloor);
        var delay = 0;
        var _loop_1 = function (step) {
            if (!step.isRoom) {
                var p = step.element;
                var l = p.length();
                var runner = p.animate();
                var timeline = runner.timeline();
                p.show();
                p.stroke({
                    dasharray: "" + l,
                    dashoffset: l * (step.flipped ? -1 : 1),
                });
                var r = runner
                    .delay(delay)
                    .animate(l * S_TO_MS / ANIMATION_SPEED)
                    .ease('-')
                    .stroke({
                    dashoffset: 0,
                });
                if (step.floor !== previousFloor) {
                    previousFloor = step.floor;
                    r.queue(function () {
                        _this.logic.showFloor(step.floor);
                    });
                }
                this_1.activePath.push({
                    path: p,
                    timeline: timeline,
                });
                delay += l * S_TO_MS / ANIMATION_SPEED;
            }
        };
        var this_1 = this;
        try {
            for (var path_1 = __values(path), path_1_1 = path_1.next(); !path_1_1.done; path_1_1 = path_1.next()) {
                var step = path_1_1.value;
                _loop_1(step);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (path_1_1 && !path_1_1.done && (_a = path_1.return)) _a.call(path_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    return PathController;
}());
export default PathController;
