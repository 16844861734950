import { __read, __values } from "tslib";
var POI = /** @class */ (function () {
    function POI(wayfinder, data) {
        var e_1, _a;
        this.wayfinder = wayfinder;
        this.data = data;
        this.advertisements = [];
        this.alwaysVisible = parseInt(data.always_visible) === 1;
        this.imageId = parseInt(data.image_id);
        this.id = parseInt(data.id);
        this.descriptions = new Map();
        try {
            for (var _b = __values(Object.entries(data)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                if (key.startsWith('descriptions_')) {
                    this.descriptions.set(key.substr('descriptions_'.length), "" + value);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    POI.prototype.getAdvertisements = function () {
        return this.advertisements;
    };
    POI.prototype.getFloor = function () {
        return this.floor;
    };
    // eslint-disable-next-line class-methods-use-this
    POI.prototype.getGroupNames = function () {
        return {};
    };
    POI.prototype.getName = function (language) {
        return this.data["names_" + language];
    };
    POI.prototype.getRoomId = function () {
        return parseInt(this.data.room_id);
    };
    POI.prototype.getShowInMenu = function () {
        return this.data.show_in_menu;
    };
    return POI;
}());
export default POI;
