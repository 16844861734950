import { __read, __values } from "tslib";
var PathGraph = /** @class */ (function () {
    function PathGraph(building) {
        this.rooms = new Map();
        this.setup(building);
    }
    PathGraph.prototype.setup = function (building) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f, e_7, _g;
        var _h, _j, _k, _l;
        this.edges = new Map();
        try {
            for (var _m = __values(building.getFloors()), _o = _m.next(); !_o.done; _o = _m.next()) {
                var _p = __read(_o.value, 2), id = _p[0], floor = _p[1];
                var floorMap = new Map();
                this.rooms.set(id, floorMap);
                try {
                    for (var _q = (e_2 = void 0, __values(floor.rooms)), _r = _q.next(); !_r.done; _r = _q.next()) {
                        var _s = __read(_r.value, 2), room = _s[0], element = _s[1];
                        floorMap.set(room, {
                            _points: 0,
                            connectsTo: [],
                            element: element,
                            flipped: false,
                            floor: id,
                            id: "room-" + room,
                            isRoom: true,
                            position: {
                                x: 0,
                                y: 0,
                            },
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_r && !_r.done && (_b = _q.return)) _b.call(_q);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_o && !_o.done && (_a = _m.return)) _a.call(_m);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var pathMap = new Map();
        try {
            for (var _t = __values(building.getFloors()), _u = _t.next(); !_u.done; _u = _t.next()) {
                var _v = __read(_u.value, 2), floor = _v[0], floorEl = _v[1];
                try {
                    for (var _w = (e_4 = void 0, __values(floorEl.paths)), _x = _w.next(); !_x.done; _x = _w.next()) {
                        var path = _x.value;
                        var id = path.attr('wf:path') || path.id();
                        var pathHelper = {
                            ends: (_h = path.attr('wf:path-connections-end'), (_h !== null && _h !== void 0 ? _h : '')).split(' '),
                            floor: floor,
                            oneDirection: path.attr().hasOwnProperty('wf:path-one-direction'),
                            starts: (_j = path.attr('wf:path-connections-start'), (_j !== null && _j !== void 0 ? _j : '')).split(' '),
                        };
                        pathMap.set(id, pathHelper);
                        this.edges.set(id, [
                            {
                                _points: 1,
                                connectsTo: [],
                                element: path,
                                flipped: false,
                                floor: floor,
                                id: id,
                                isRoom: false,
                                position: path.pointAt(path.length()),
                            }, {
                                _points: 1,
                                connectsTo: [],
                                element: path,
                                flipped: true,
                                floor: floor,
                                id: id,
                                isRoom: false,
                                position: path.pointAt(0),
                            },
                        ]);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_x && !_x.done && (_d = _w.return)) _d.call(_w);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_u && !_u.done && (_c = _t.return)) _c.call(_t);
            }
            finally { if (e_3) throw e_3.error; }
        }
        try {
            for (var pathMap_1 = __values(pathMap), pathMap_1_1 = pathMap_1.next(); !pathMap_1_1.done; pathMap_1_1 = pathMap_1.next()) {
                var _y = __read(pathMap_1_1.value, 2), id = _y[0], path = _y[1];
                var edge = this.edges.get(id)[0];
                var reverseEdge = this.edges.get(id)[1];
                try {
                    for (var _z = (e_6 = void 0, __values(path.ends)), _0 = _z.next(); !_0.done; _0 = _z.next()) {
                        var end = _0.value;
                        var roomMatch = /room-(.*)/.exec(end);
                        if (roomMatch) {
                            var room = (_k = this.rooms.get(path.floor)) === null || _k === void 0 ? void 0 : _k.get(parseInt(roomMatch[1]));
                            if (room) {
                                edge.connectsTo.push(room);
                                if (!path.oneDirection) {
                                    room.connectsTo.push(reverseEdge);
                                }
                                room._points++;
                                room.position = {
                                    x: ((room.position.x * (room._points - 1)) + edge.position.x) / room._points,
                                    y: ((room.position.y * (room._points - 1)) + edge.position.y) / room._points,
                                };
                            }
                        }
                    }
                }
                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                finally {
                    try {
                        if (_0 && !_0.done && (_f = _z.return)) _f.call(_z);
                    }
                    finally { if (e_6) throw e_6.error; }
                }
                try {
                    for (var _1 = (e_7 = void 0, __values(path.starts)), _2 = _1.next(); !_2.done; _2 = _1.next()) {
                        var start = _2.value;
                        var roomMatch = /room-(.*)/.exec(start);
                        if (roomMatch) {
                            var room = (_l = this.rooms.get(path.floor)) === null || _l === void 0 ? void 0 : _l.get(parseInt(roomMatch[1]));
                            if (room) {
                                room.connectsTo.push(edge);
                                if (!path.oneDirection) {
                                    reverseEdge.connectsTo.push(room);
                                }
                                room._points++;
                                room.position = {
                                    x: ((room.position.x * (room._points - 1)) + reverseEdge.position.x) / room._points,
                                    y: ((room.position.y * (room._points - 1)) + reverseEdge.position.y) / room._points,
                                };
                            }
                        }
                        else {
                            var startMatch = /(.*)-start/.exec(start);
                            if (startMatch) {
                                var connection = this.edges.get(startMatch[1]);
                                if (connection) {
                                    connection[1].connectsTo.push(edge);
                                    if (!path.oneDirection) {
                                        reverseEdge.connectsTo.push(connection[0]);
                                    }
                                }
                            }
                            else {
                                var connection = this.edges.get(start);
                                if (connection) {
                                    connection[0].connectsTo.push(edge);
                                    if (!path.oneDirection) {
                                        reverseEdge.connectsTo.push(connection[1]);
                                    }
                                }
                            }
                        }
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (_2 && !_2.done && (_g = _1.return)) _g.call(_1);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (pathMap_1_1 && !pathMap_1_1.done && (_e = pathMap_1.return)) _e.call(pathMap_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    PathGraph.prototype.findPath = function (startFloor, startRoom, endFloor, endRoom) {
        var e_8, _a;
        var _b, _c;
        var start = (_b = this.rooms.get(startFloor)) === null || _b === void 0 ? void 0 : _b.get(startRoom);
        if (!start) {
            throw "No room " + startRoom + " on floor " + startFloor;
        }
        var end = (_c = this.rooms.get(endFloor)) === null || _c === void 0 ? void 0 : _c.get(endRoom);
        if (!end) {
            throw "No room " + endRoom + " on floor " + endFloor;
        }
        var dist = function (connection) {
            return Math.sqrt(Math.pow((end.position.x - connection.position.x), 2) + Math.pow((end.position.y - connection.position.y), 2));
        };
        var first = {
            connection: start,
            f: dist(start),
            from: null,
            length: 0,
        };
        var lengths = new Map();
        var open = start.connectsTo.map(function (edge) {
            var length = edge.isRoom ? 0 : edge.element.length();
            lengths.set(edge.id, length);
            return {
                connection: edge,
                f: length + dist(edge),
                from: first,
                length: length,
            };
        });
        var closest = function () {
            var e_9, _a;
            var min = Infinity;
            var closestI = 0;
            var closestP = null;
            try {
                for (var _b = __values(open.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), i = _d[0], p = _d[1];
                    if (p.f < min) {
                        min = p.f;
                        closestI = i;
                        closestP = p;
                    }
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_9) throw e_9.error; }
            }
            open.splice(closestI, 1);
            return closestP;
        };
        var current;
        while (current = closest()) {
            if (current.connection.id === "room-" + endRoom) {
                var sortedPath = [current.connection];
                var previous = current.from;
                while (previous !== null) {
                    sortedPath.push(previous.connection);
                    previous = previous.from;
                }
                return sortedPath.reverse();
            }
            try {
                for (var _d = (e_8 = void 0, __values(current.connection.connectsTo)), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var connection = _e.value;
                    var length_1 = current.length + (connection.isRoom ? 0 : connection.element.length());
                    var existing = lengths.get(connection.id);
                    if (!existing || existing > length_1) {
                        open.push({
                            connection: connection,
                            f: length_1 + dist(connection),
                            from: current,
                            length: length_1,
                        });
                        lengths.set(connection.id, length_1);
                    }
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
        throw "No path found between rooms " + startRoom + " and " + endRoom;
    };
    return PathGraph;
}());
export default PathGraph;
