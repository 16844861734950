import { __extends, __read, __values } from "tslib";
import PoiSVG from './data/PoiSVG';
import WayfinderFactory from 'WayfinderFactory';
import FloorSVG from './data/FloorSVG';
import BuildingSVG from './data/BuildingSVG';
import PathGraph from './PathGraph';
var WayfinderFactorySVG = /** @class */ (function (_super) {
    __extends(WayfinderFactorySVG, _super);
    function WayfinderFactorySVG() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WayfinderFactorySVG.createBuilding = function (buildingData) {
        return new BuildingSVG(buildingData);
    };
    WayfinderFactorySVG.createFloor = function (floorData) {
        return new FloorSVG(floorData);
    };
    WayfinderFactorySVG.prototype.createRooms = function (svg) {
        var e_1, _a;
        var _b, _c;
        try {
            for (var _d = __values(svg.find('[wf\\:room]')), _e = _d.next(); !_e.done; _e = _d.next()) {
                var element = _e.value;
                var room = parseInt(element.attr('wf:room'));
                var floorElement = element.parent('[wf\\:floor]');
                var floorId = "" + ((_b = floorElement) === null || _b === void 0 ? void 0 : _b.attr('wf:floor'));
                if (!isNaN(room) && floorId) {
                    if (element.type !== 'g') {
                        try {
                            var group = floorElement.group();
                            group.put(element);
                            element.attr('wf:room', null);
                            group.attr('wf:room', room);
                            element = group;
                        }
                        catch (_) {
                            this.wayfinder.events.trigger('error-loading', 'Malformed SVG: wf:room must be <g> or descendant of wf:floor');
                            continue;
                        }
                    }
                    (_c = this.wayfinder.building.getFloor(floorId)) === null || _c === void 0 ? void 0 : _c.rooms.set(room, element);
                    var poi = this.wayfinder.roomToPoi.get(room);
                    if (poi) {
                        poi.setElement(floorId, element);
                        poi.createGeometry(svg);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    WayfinderFactorySVG.prototype.createFloors = function (svg) {
        var e_2, _a;
        var i = 0;
        try {
            for (var _b = __values(svg.find('[wf\\:floor]')), _c = _b.next(); !_c.done; _c = _b.next()) {
                var floor = _c.value;
                svg.put(floor); // So floors would always be top-level
                var id = "" + floor.attr('wf:floor');
                if (this.wayfinder.building.getFloor(id)) {
                    this.wayfinder.events.trigger('warn-loading', "Malformed SVG: Duplicate floor definition for floor " + id);
                    floor.remove();
                    continue;
                }
                var pathsElement = floor.find('g[wf\\:paths]');
                var paths = void 0;
                if (pathsElement.length) {
                    paths = pathsElement[0];
                }
                else {
                    paths = floor.group();
                    paths.attr('wf:paths', true);
                }
                var poisElement = floor.find('g[wf\\:poi-geometry]');
                var pois = void 0;
                if (poisElement.length) {
                    pois = poisElement[0];
                }
                else {
                    pois = floor.group();
                    pois.attr('wf:poi-geometry', true);
                    pois.attr('wf:internal', true);
                }
                pois.front();
                paths.front();
                this.wayfinder.building.addFloor(WayfinderFactorySVG.createFloor({
                    element: floor,
                    id: "" + floor.attr('wf:floor'),
                    index: i++,
                    paths: paths,
                    pois: pois,
                    showInMenu: true,
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    WayfinderFactorySVG.prototype.createLocations = function (pois) {
        var e_3, _a;
        try {
            for (var pois_1 = __values(pois), pois_1_1 = pois_1.next(); !pois_1_1.done; pois_1_1 = pois_1.next()) {
                var poiData = pois_1_1.value;
                var poi = new PoiSVG(this.wayfinder, poiData);
                var room = parseInt(poiData.room_id);
                this.wayfinder.pois.set(poi.id, poi);
                if (room > 0) {
                    this.wayfinder.roomToPoi.set(room, poi);
                    this.wayfinder.poiIdToRoom.set(parseInt(poiData.id), room);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (pois_1_1 && !pois_1_1.done && (_a = pois_1.return)) _a.call(pois_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    WayfinderFactorySVG.prototype.createPaths = function () {
        var e_4, _a, e_5, _b;
        try {
            for (var _c = __values(this.wayfinder.building.getFloors()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), floor = _e[1];
                floor.paths.splice(0, floor.paths.length);
                try {
                    for (var _f = (e_5 = void 0, __values(floor.element.find('[wf\\:path]'))), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var path = _g.value;
                        path.hide();
                        floor.paths.push(path);
                        floor.pathGroup.put(path);
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                floor.pathGroup.back();
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.wayfinder.pathGraph = new PathGraph(this.wayfinder.building);
    };
    WayfinderFactorySVG.prototype.setup = function (svg, data) {
        this.wayfinder.building = WayfinderFactorySVG.createBuilding(data);
        this.createFloors(svg);
        this.createLocations(data.all);
        this.createRooms(svg);
        // Last
        this.createPaths();
    };
    return WayfinderFactorySVG;
}(WayfinderFactory));
export default WayfinderFactorySVG;
