import Building from 'data/Building';
import Floor from 'data/Floor';
var WayfinderFactory = /** @class */ (function () {
    function WayfinderFactory() {
    }
    WayfinderFactory.prototype.addPOIsToFloor = function (_floorPOIs) {
        /* for (var floor_id in floorPOIs) {
            var floors = this.wayfinder.building.getFloors();
            if (!(floor_id in floors)) continue;
            var floor = floors[floor_id];
            for (var i in floorPOIs[floor_id]) {
                if (!(floorPOIs[floor_id][i] in this.wayfinder.pois)) continue;
                floor.addPOI(this.wayfinder.pois[floorPOIs[floor_id][i]]);
            }
        }*/
    };
    WayfinderFactory.prototype.createAdvertisements = function (_poiAdsData) {
        /* if (poiAdsData) {
            for (var poiAdIndex in poiAdsData) {
                var poiAdData = poiAdsData[poiAdIndex];
                var poi = this.wayfinder.pois[poiAdData["poi_id"]];
                if (poi) {
                    var poiAd = this.createPOIAdvertisement(poi, poiAdData, this.wayfinder.languages);
                    this.wayfinder.poiAdvertisements[poiAdData["id"]] = poiAd;
                    poi.addAdvertisement(poiAd);
                }
            }
        }*/
    };
    WayfinderFactory.prototype.createAttributes = function (_attributes) {
        /* if (attributes) {
            this.wayfinder.attributes = attributes;
        }*/
    };
    WayfinderFactory.prototype.createBuilding = function (data) {
        return new Building(data);
    };
    WayfinderFactory.prototype.createEdges = function (_edges) {
        // this.wayfinder.edges = edges;
    };
    WayfinderFactory.prototype.createFloor = function (floorData) {
        return new Floor(floorData);
    };
    WayfinderFactory.prototype.createFloors = function (_floors) {
        /* if (floors && this.wayfinder.building) {
            for (var i in floors) {
                if (typeof floors[i] === 'object') {
                    this.wayfinder.building.addFloor(this.createFloor(floors[i], this.wayfinder.languages));
                }
            }
        }*/
    };
    WayfinderFactory.prototype.createGroups = function (_poiGroupsData, _poisInGroupsData) {
        /* if (poisInGroupsData && poiGroupsData) {
            var poiGroup, poiGroupData;
            // Create poi groups and add POIs to poi groups
            for (poiGroupData in poiGroupsData) {
                poiGroup = this.createPOIGroup(poiGroupsData[poiGroupData], this.wayfinder.languages);
                this.wayfinder.poiGroups[poiGroup.getID()] = poiGroup;
            }

            // Add POI groups to POI
            for (var poiGroupID in poisInGroupsData) {
                poiGroupData = poisInGroupsData[poiGroupID];
                for (var poiIndex in poiGroupData) {
                    var poi = this.wayfinder.pois[poiGroupData[poiIndex]];
                    poiGroup = this.wayfinder.poiGroups[poiGroupID];
                    if (poi && poiGroup) {
                        poiGroup.addPOI(poi);
                        poi.addGroup(poiGroup);
                    }
                }
            }
        }*/
    };
    WayfinderFactory.prototype.createLanguages = function (_languages) {
        /* for (var name in languages) {
            this.wayfinder.languages[name] = new Language(languages[name]);
            if (name.toLowerCase() == this.wayfinder.options.language.toLowerCase()) {
                this.wayfinder.translator.language = name;
            }
        }
        this.wayfinder.translator.translate();*/
    };
    WayfinderFactory.prototype.createLocations = function (_data) {
        /* this.wayfinder.factory.createNodes(data.navigation.nodes);
        this.wayfinder.factory.createEdges(data.navigation.edges);
        this.wayfinder.factory.createPOIs(data.locations.all);
        this.wayfinder.factory.addPOIsToFloor(data.locations.byfloor);
        this.wayfinder.factory.createGroups(data.locations.groups, data.locations.bygroup);
        this.wayfinder.factory.createTags(data.locations.tags);

        if (this.wayfinder.options.filterPOIs) {
            this.wayfinder.factory.filterPOIs(this.wayfinder.options.filterPOIs.trim().split(","));
        }

        this.wayfinder.advertisements = data.a;

        Logistics.getJSON(this.wayfinder.api.navigation.allAttributes.url(), null, ClassCallback(this.wayfinder.factory, this.wayfinder.factory.createAttributes), { "stage": "settings" });*/
    };
    WayfinderFactory.prototype.createNode = function (_data) {
        // return new NavigationNode(data);
    };
    WayfinderFactory.prototype.createNodes = function (_nodes) {
        /* if (nodes) {
            var defaultKiosk = this.wayfinder.settings.getInt('kiosk.default', 0);
            var floors = this.wayfinder.building.getFloors();
            for (var i = 0; i < nodes.length; i++) {
                var node = this.createNode(nodes[i]);
                if (!node) continue;
                this.wayfinder.nodes[nodes[i].id] = node;
                if (node.floor_id in floors) {
                    floors[node.floor_id].addNode(node);
                }

                if (nodes[i].id == defaultKiosk) {
                    this.wayfinder.kiosk = node;
                }
            }
        }*/
    };
    WayfinderFactory.prototype.createPOI = function (_data, _languages) {
        // return new POI(data, languages);
    };
    WayfinderFactory.prototype.createPOIAdvertisement = function (_poi, _data, _languages) {
        // return new POIAdvertisement(poi, data, languages);
    };
    WayfinderFactory.prototype.createPOIGroup = function (_data, _languages) {
        // return new POIGroup(data, languages);
    };
    WayfinderFactory.prototype.createPOIs = function (_pois) {
        /* for (var i = 0; i < pois.length; i++) {
            var poi = this.createPOI(pois[i], this.wayfinder.languages);
            this.wayfinder.pois[pois[i].id] = poi;
            this.wayfinder.poisArray.push(poi);
            if (poi.node_id in this.wayfinder.nodes) {
                this.wayfinder.nodes[poi.node_id].addPOI(poi);
            }
            // Here here
            if (this.wayfinder.poisettings && this.wayfinder.poisettings.data && this.wayfinder.poisettings['data'][poi.id]) {
                poi.settings.data = this.wayfinder.poisettings['data'][poi.id];
            }
        }*/
    };
    WayfinderFactory.prototype.createTags = function (_tags) {
        /* if (tags) {
            for (var t in tags) {
                var tag = tags[t];
                var poi = this.wayfinder.pois[tag["poi_id"]];
                if (poi) {
                    poi.setTags(tag["tags"]);
                }
            }
        }*/
    };
    WayfinderFactory.prototype.filterPOIs = function (_tags) {
        /* if (tags && tags.length > 0) {
            var poi;
            var tag = "";
            for (var j in tags) {
                tag = tags[j].trim();
                if (tag && tag !== "") {
                    for (var i in this.wayfinder.pois) {
                        poi = this.wayfinder.pois[i];
                        poi.setShowInMenu(false);
                        if (poi.getTags().indexOf(tag) > -1) {
                            poi.setShowInMenu(true);
                            continue;
                        }
                    }
                }
            }
        }*/
    };
    return WayfinderFactory;
}());
export default WayfinderFactory;
